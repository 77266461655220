import React from 'react';
import { getURL } from 'src/components/util/url';
import './location-item.scss';

export default ({
  photo,
  address,
  title,
  description,
}) => (
  <div className="location-container">
    <img src={getURL(photo)} alt="title" />
    <div>
      <h2>{title}</h2>
      <h3>{address}</h3>
      {
        description ? (
          <p>{description}</p>
        ) : ''
      }
    </div>
  </div>
);
