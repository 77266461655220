import React, { useMemo, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from 'src/components/button/Button';
import Checkbox from 'src/components/checkbox/Checkbox';
import Container from 'src/components/UI/Container';
import Header from 'src/components/header/Header';
import LocationItem from 'src/components/location/location-item';
import { Helmet } from "react-helmet"
import Footer from 'src/components/footer/Footer';
import './contact.scss';

const query = graphql`
  query {
    allLocationsJson {
      edges {
        node {
          id
          address
          photo
          title
          description
          order
        }
      }
    }
    contactText: allWebsiteTextsJson(filter: {identifier: {eq: "contact"}}) {
      nodes {
        title
        text
        id
        shown
      }
    }
  }
`;

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [GDPRConsent, setGDPRConsent] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    allLocationsJson: { edges },
    contactText: { nodes: [contactText] },
  } = useStaticQuery(query);

  const locations = useMemo(() => {
    return edges.map(({ node }) => ({ ...node }));
  }, [edges]);

  const handleCheckboxChanged = ({ target: { checked } }) => {
    setGDPRConsent(checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: encode({
        'form-name': 'contact',
        name,
        email,
        phone,
        message,
      }),
    }).then(() => {
      setIsSubmitted(true);
    }).catch((error) => console.error(error));

  }

  return (
    <div className="page contact">
      <Header />
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;900&display=swap" rel="stylesheet" />
        <title>BJJ Gent | Contact</title>
      </Helmet>

      <Container className="dark contact-container offset-from-top">
        {
          contactText.shown ? (
            <div className="info-container">
              <h2 className="uppercase">{contactText.title}</h2>
              {
                contactText.text.split('\n').map((paragraph, index) => (
                  <p key={`${contactText.title}-${index}`}>{paragraph}</p>
                ))
              }
            </div>
          ) : ''
        }

        {
          isSubmitted ? (
            <h2 className="form-feedback">Thanks for reaching out! We'll get back to you soon!</h2>
          ) : ''
        }

        <form
          onSubmit={handleSubmit}
          className={`dark ${isSubmitted ? 'submitted' : ''}`}
          data-netlify="true"
          name="contact"
          method="POST"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="floating-label-field">
            <input type="text" name="name" placeholder="Name" value={name} onChange={({ target: { value } }) => setName(value)} required />
            <label>Your name*</label>
          </div>
          <div className="floating-label-field">
            <input type="text" name="email" placeholder="E-mail" value={email} onChange={({ target: { value } }) => setEmail(value)} required />
            <label>E-mail*</label>
          </div>
          <div className="floating-label-field">
            <input type="text" name="phone" placeholder="Phone" value={phone} onChange={({ target: { value } }) => setPhone(value)} pattern="[\+0-9\s]+" />
            <label>Phone</label>
          </div>
          <div>
            <textarea name="message" placeholder="Message*" value={message} onChange={({ target: { value } }) => setMessage(value)} required />
          </div>

          <div className="gdpr-consent">
            <Checkbox handleChange={handleCheckboxChanged} />
            <p>
              By checking this box, you agree that your data will be processed by us. We don't use your data for any commercial purposes. The data is used for the sole purpose of reaching out to you. [GDPR]
            </p>
          </div>

          <Button disabled={!GDPRConsent} type="submit">Submit mail</Button>
        </form>
      </Container>

      <Container className="locations-container">
        <h2 className="uppercase">Locations</h2>

        {
          locations.sort((a, b) => a.order - b.order).map(({
            id,
            photo,
            address,
            title,
            description,
          }) => (
            <LocationItem
              key={id}
              photo={photo}
              address={address}
              title={title}
              description={description} />
          ))
        }
      </Container>

      <Footer />
    </div>
  );
};
